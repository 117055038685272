.header {
  width: 90vw;
  margin: 0 auto;
  padding: 2rem;
  font-size: 1.2rem;
  position: relative;
  overflow: hidden;
}

.header-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* overflow: hidden; */
}

.header-icon-container {
  display: flex;
  align-items: center;
  gap: .5rem;
  justify-content: center;
}

.header-icons {
  color: rgb(86, 83, 83);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: .2rem;
}

.header-icons:hover,
.header-icons:focus {
  outline: none;
  background-color: rgb(86, 83, 83);
  color: white;
  transform: .3s all ease-in-out;
}

header::after {
  content: "";
  /* This is necessary for the pseudo element to work. */
  display: block;
  /* This will put the pseudo element on its own line. */
  margin: 0 auto;
  /* This will center the border. */
  /* width: 90%; */
  /* Change this to whatever width you want. */
  padding-top: 10px;
  /* This creates some space between the element and the border. */
  border-bottom: 1px solid black;
  /* This creates the border. Replace black with whatever color you want. */
}

@media screen and (min-width: 768px) {
  header {
    font-size: 2rem;
  }

  header::after {
    padding-top: 20px;
  }

  .header-icons {
    padding: .7rem;
  }
}