body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Saira', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin: 0 auto;
  text-align: center;
  padding: 0 2rem;
  max-width: 900px;
}

.project-header-text {
  font-size: 4rem;
  margin-bottom: 2rem;
}

.contact-header-text {
  font-size: 4rem;
  margin-bottom: 3.2rem;
}

/* WELCOME */
.home {
  min-height: 80vh;
  /* overflow: hidden; */
}

.hello-text {
  width: auto;
  margin: 2rem auto;
}

.welcome-text {
  font-size: 1.4rem;
  margin: 0 auto;
  max-width: 500px;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.button-text-span {
  display: inline-block;
}

.step-button {
  width: 9rem;
  padding: .8rem 1.6rem 0.6rem;
  font-size: .8rem;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid black;
  margin: auto 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  text-transform: uppercase;
}

.step-button:focus {
  outline: none;
}

.project-cards-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.project {
  padding-bottom: 8rem;
}

.button-container.projects-buttons {
  padding: 2rem 0;
  gap: 1rem;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
}

.contact-list-container {
  margin: 2rem auto 4rem;
  max-width: 300px;
}

.contact-list-container li {
  list-style-type: none;
  margin-bottom: 1rem;
  text-align: left;
  color: black;
}

.contact-list-container li a {
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 3rem;
}

.contact-list-container li a:focus {
  outline: none;
  scale: 1.4;
  color: rgba(80, 112, 234, 0.8);
  transform-origin: left;
  transition: cubic-bezier(0.39, 0.575, 0.565, 1)
}

.link-text {
  display: inline-block;
  padding-left: 1rem;
  color: inherit;
}

.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;

}

.not-found-title {
  font-size: 10rem;
  font-weight: 700;
}

.not-found-subtitle {
  font-size: 4rem;
  font-weight: 500;
  padding: 0 15rem;
}


@media screen and (min-width: 786px) {
  .hello-text {
    width: 500px;
  }

  .welcome-text {
    font-size: 1.5rem;
  }

  .project-header-text {
    margin-bottom: 3.2rem;
  }

  .project {
    padding-bottom: 10rem;
  }

  .project-cards-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .button-container.projects-buttons {
    gap: 2rem;
  }

  .step-button {
    width: 11rem;
    font-size: 1rem;
  }

  .contact-list-container li a {
    margin-left: 4rem;
  }
}