.card-image-container {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

@media screen and (min-width: 768px) {
  .card-image-container {
    height: 300px;
    margin-bottom: 0;
    padding: 1rem;
  }
}