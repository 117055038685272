* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card-container {
  width: 95%;
  height: auto;
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: 0 0 20px 8px #d0d0d0;
  padding: 1rem 2rem;
}

img {
  width: 100%;
}

.card-title {
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.card-title-link {
  /* margin-top: -1rem;
  margin-bottom: 0.5rem; */
  text-decoration: none;
  color: black;
  transition: .3s ease-in-out;
}

a.card-title-link:focus {
  outline: none;
}

a.card-title-link:focus .card-title {
  background-color: black;
  color: white;
  transition: .3s ease-in-out;
}

.card-text {
  text-align: justify;
  margin-bottom: 0.5rem;
  height: auto;
}

.card-link-outer-container {
  display: flex;
  justify-content: right;
  gap: .5rem;
}

.card-link {
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding: .3rem;
}

.card-link:hover {
  color: rgba(80, 112, 234, 0.8);
  transition: all .3s ease-in-out;
}

.card-link:focus {
  outline: none;
  background-color: black;
  color: white;
  transition: all .3s ease-in-out
}

@media screen and (min-width: 768px) {
  .card-container {
    width: 400px;
    /* height: 550px; */
  }

  .card-title {
    font-size: 1.5rem;
    letter-spacing: 3px;
  }

  .card-text {
    height: 150px
  }

  .card-link-outer-container{
    gap: .3rem;
  }
}